/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Tab {
    &-Item {
        margin: 0;

        &:last-child {
            margin: 0;
        }

        &::before {
            content: '';
        }

        &_isActive {
            .Tab-Button {
                font-family: $font-HyundaiSansHeadKR;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                color: $default-primary-base-color;
                border-block-end: 4px solid $default-primary-base-color;

                @include mobile {
                    font-family: $font-HyundaiSansTextKR;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                }
            }
        }
    }

    &-Button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-block-start: 20px;
        padding-block-end: 8px;
        cursor: pointer;
        font-family: $font-HyundaiSansHeadKR;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: var(--disabled-color);
        text-transform: capitalize;
        transition: all 0.2s ease-out;
        border-block-end: 4px solid var(--stroke-color);

        @include mobile {
            font-family: $font-HyundaiSansTextKR;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            border-block-end: 1px solid var(--disabled-color);
        }
    }
}
