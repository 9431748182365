/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

@mixin OverlaySettings {
    &.Overlay {
        position: fixed;
        inset-inline-start: 0;
        inset-block-start: 0;
        height: 100%;
        width: 100%;
    }
}

.MenuPage {
    padding-block-start: 60px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-end: 0;

    @include mobile {
        padding-block-end: var(--navigation-tabs-height);
        background-color: white;
        z-index: 11;
    }
}

.MobileMenuOverlay {
    @include OverlaySettings();

    /* stylelint-disable-next-line declaration-no-important */
    padding-block-end: 0 !important;

    .MenuOverlay-CloseButton {
        position: absolute;
        inset-inline-end: 16px;
        inset-block-start: 16px;
        cursor: pointer;

        svg {
            width: 28px;
            height: 28px;
        }
    }
}

.MenuOverlay {
    @include OverlaySettings();

    .Menu {
        &-MainCategories {
            margin: 0;
        }

        &-MenuWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline-end: 15%;
        }

        &-ItemList {
            flex-direction: column;
            columns: unset;

            &_type_main {
                //Primary menu item styles

                .Menu-Item {
                    cursor: pointer;
                    position: relative;
                    width: fit-content;
                    height: unset;

                    &:not(:last-child) {
                        margin-block-end: 25px;
                    }

                    &:hover {
                        &::before {
                            content: "";
                            background-color: var(--body-content-color);
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            inset-inline-start: 50%;
                            inset-block-start: 50%;
                            transform: translate(-50%,-50%);
                            padding: 8px 20px;
                        }

                        [dir="rtl"] &::before {
                            transform: translate(50%,-50%);
                        }

                        .Menu-ItemCaption {
                            &_type_main {
                                color: var(--color-white);
                            }
                        }
                    }
                }
            }
        }

        &-ItemCaption {
            &_type_main {
                @include desktop {
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 52px;
                    margin: 0;
                }
            }
        }
    }

    &-MenuOverlayWrapper {
        display: grid;
        grid-template-columns: 1fr 401px;
        width: 100%;
        height: 100%;
        padding: 40px 80px;
    }

    &-CloseButton {
        position: absolute;
        inset-inline-end: 50px;
        inset-block-start: 40px;
        cursor: pointer;

        svg {
            width: 28px;
            height: 28px;
        }
    }
}
