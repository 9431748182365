/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CompareTrims {
    &.Popup {
        margin: 1px 0 0 0;
        z-index: 10;

        @include desktop {
            display: block;
            height: calc(100% - 2 * var(--header-total-height));
            inset-block-start: calc(2 * var(--header-total-height));
        }

        .CompareTrims {
            &-Table::-webkit-scrollbar-thumb {
                background: var(--primary-base-color);
            }

            &-Table::-webkit-scrollbar {
                height: 4px;
            }

            &-Table::-webkit-scrollbar-track {
                background: var(--negative-color);
            }
        }
    }

    .Popup {
        &-Content {
            padding: 18px 16px;

            @include desktop {
                width: 100%;
                max-width: initial;
                height: 100%;
                overflow-y: auto;
                padding: 44px 52px 108px;
            }
        }
    }

    &-Heading {
        text-transform: initial;
        display: flex;
        align-items: center;
        margin: 0 0 16px;

        h3 {
            margin: 0 auto 0 0;

            @include desktop {
                line-height: 52px;
                font-size: 40px;
                margin: 0 auto 28px 0;
            }
        }
    }

    &-BackButton.Breadcrumb-Link {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 2px 0 0;

        svg {
            @include desktop {
                margin: 0 0 4px 0;
            }
        }
    }

    &-ScrollButton {
        border: 1px solid #D9D9D9;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_isDisabled {
            &:hover,
            svg:hover {
                cursor: not-allowed;
            }

            svg {
                fill: var(--disabled-color);
            }
        }
    }

    &-ScrollRight {
        &::before {
            transform: rotate(-135deg);
            margin: 0 4px 0 0;
        }
    }

    &-ScrollButtons {
        display: flex;
        gap: 12px;

        @include desktop {
            align-self: flex-end;
        }
    }

    td,
    th {
        height: 60px;
        z-index: 1;
        border-bottom: 1px solid var(--stroke-color);
        border-right: 1px solid var(--stroke-color);
        padding: 18px 12px;
        font-size: 16px;
        line-height: 24px;
        min-width: 175px;

        @include desktop {
            min-width: 269px;
        }

        &:first-child {
            position: sticky;
            inset-inline-start: 0;
            z-index: 2;
            min-width: 126px;
            width: 15%;

            @include desktop {
                min-width: 260px;
                width: 19%;
            }
        }
    }

    &-Wrapper {
        &::after {
            content: '';
            transition: all .05s ease-in;
            position: absolute;
            inset-block-start: 0;
            height: calc(100% - 16px);
            width: 126px;
            z-index: 1;

            @include desktop {
                width: 260px;
                height: calc(100% - 20px);
            }
        }

        &_isScrolled {
            &::after {
                box-shadow: 23px 0px 25px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }

    &-Table {
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        padding: 0 0 8px 0;

        @include desktop {
            padding: 0 0 16px 0;
        }

        table {
            margin: initial;
            border-collapse: separate;
            table-layout: fixed;

            tbody tr {
                border-bottom: initial;
            }
        }

        &_isScrolled {
            td,
            th {
                &:first-child::before {
                    opacity: .7;
                }
            }
        }

        > h3 {
            text-transform: initial;
        }
    }

    th,
    td:first-child {
        background-color: var(--primary-base-color);
        color: var(--color-white);
        font-weight: 700;
    }

    th {
        border-radius: initial;

        &:not(:first-child) {
            @include desktop {
                font-size: 22px;
                line-height: 28px;
            }
        }
    }

    tr:nth-child(even) {
        background-color: var(--surface-color);
    }

    &-Breadcrumbs.Breadcrumbs {
        @include desktop {
            height: 60px;
            display: flex;
            align-items: center;
            padding: 0 52px;
        }
    }
}
