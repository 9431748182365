/* stylelint-disable declaration-no-important */

.FAQ {
    &-ContentWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-HeadingWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 36px;
        max-width: 846px;
        margin: 0 0 20px 0;
    }

    &-Heading {
        font-style: normal;
        font-weight: 700;
        text-align: center;
        color: #000000;
        text-transform: none;

        @include desktop {
            font-size: 40px;
            line-height: 52px;
        }

        @include mobile {
            font-size: 26px;
            line-height: 32px;
        }
    }

    &-Accordion {
        .AccordionTabs-TabName {
            &::after {
                transform: scale(1.5);
            }

            &_isSelected {
                &::after {
                    transform: scale(1.5) rotate(180deg) !important;
                }
            }

            @include desktop {
                &::after {
                    inset-inline-end: 3.5% !important;
                    inset-block-end: 45%;
                }
            }

            @include mobile {
                &::after {
                    inset-block-end: 45%;
                }
            }
        }
    }

    &-Description {
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }

    &-AccordionWrapper {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-bottom: none;
    }

    &-AccordionHeading {
        font-style: normal;
        font-weight: 700;
        color: #000000;
        border-bottom: 1px solid #D9D9D9;
        padding: 24px 32px;
        min-height: 76px;
        cursor: pointer;
    }

    &-AccordionContent {
        background: #F4F4F4;
        width: 100%;

        @include desktop {
            padding: 24px 32px;
        }

        @include mobile {
            padding: 24px 16px;
        }
    }

    &-AccordionText {
        font-style: normal;
        font-weight: 400;
        color: #000000;
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 0;
    }

    &-Hide {
        text-align: start;
        margin: auto;
        text-transform: none;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
        }

        @include mobile {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
