.CmsPage {
    .Sitemap {
        &-ItemList {
            margin-inline-start: 0;
        }
    }
}

ul.Sitemap-ItemList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    row-gap: 24px;
    column-gap: 50px;
    padding-block-start: calc(var(--header-height) * 1.5);

    .Sitemap-ItemCaption {
        &_type {
            &_main {
                font-size: var(--h3-font-size);
                font-weight: var(--h3-font-weight);
                line-height: var(--h3-line-height);
            }
        }
    }
}
