/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$popupWidthDesktop: 450px;
$buttonGap: 20px;

.ActionsPopup {
    @include mobile {
        inset-block-start: calc(var(--header-total-height) - 2px);
        height: calc(100% - var(--header-total-height) + 2px);
    }

    .Popup {
        &-Header {
            padding-block-start: 36px;
            padding-block-end: 36px;
        }

        &-Content {
            width: $popupWidthDesktop;
            min-width: $popupWidthDesktop;
            min-height: 212px;

            @include mobile {
                width: 100%;
                min-width: 100%;
            }
        }

        &-ChildrenWrapper {
            padding-block-start: 0;
            padding-block-end: 60px;

            @include mobile {
                height: 100%;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &-CloseBtn {
            inset-block-start: -3px;
        }
    }

    &-Content {
        min-height: calc(100% - 32px * 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        @include desktop {
            min-height: 80px;
            padding-block: 0 32px;
        }

        @include mobile {
            margin: 0 16px;
        }

        p {
            margin-block-end: 0;
        }

        &_isTextContent {
            @include desktop {
                padding-block-start: 32px;
            }
        }
    }

    &-Buttons {
        display: flex;
        gap: $buttonGap;

        .Button {
            flex-basis: calc((100% - #{$buttonGap}) / 2);

            &_isHidden {
                display: none;
            }

            @include mobile {
                flex-basis: 100%;
            }

            @include desktop {
                font-size: 20px;
            }
        }

        .AddToCart {
            .ChevronIcon {
                display: none;
            }
        }

        @include mobile {
            gap: 0;
        }
    }

    .ActionsPopup-ButtonSecondary {
        background-color: var(--negative-color);
        border-color: var(--negative-color);

        @include desktop {
            font-size: 20px;
        }

        &:hover {
            &.Button {
                background-color: var(--negative-color-hover);
                border-color: var(--negative-color-hover);
            }
        }
    }
}
