/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.SmsCodePopup {
    &-Popup {
        @include mobile {
            inset-block-start: 0;
            height: 100%
        }

        @include small-mobile {
            inset-block-start: 0;
            height: 100%
        }

        & .Popup-Header {
            @include desktop {
                padding: 26px 26px 10px 26px;
            }

            @include mobile {
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                padding: 26px 0;
            }
        }

        & .Popup-Content {
            @include desktop {
                width: 450px;
            }

            @include mobile {
                margin-block-start: auto;
                border-radius: 10px 10px 0px 0px;
                box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
                height: 640px;
            }

            @include small-mobile {
                height: 500px;
            }
        }

        .Popup-HeaderWrapper {
            @include mobile {
                padding: 0 18px;
            }
        }

        & .Popup-ChildrenWrapper {
            @include desktop {
                padding-block-start: 36px;
                padding-block-end: 60px;
            }

            @include mobile {
                height: 100%;
            }
        }
    }

    &-ContentWrapper {
        padding-block-start: 129px;

        @include desktop {
            padding-block-start: 0;
        }

        @include mobile {
            height: 100%;
            padding-block-start: 129px;
        }

        @include small-mobile {
            padding-block-start: 45px;
        }
    }

    &-Title {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        margin-block-end: 32px;
        color: var(--body-content-color);
    }

    &-Field {
        margin-block-end: 12px;
        width: 100%;

        & input {
            @include desktop {
                width: 100%;
            }
        }
    }

    &-ValidateButton {
        margin-block-end: 44px;

        @include desktop {
            width: 100%;
        }
    }

    &-Text {
        display: block;
        margin-block-end: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: var(--body-content-color);
    }

    &-ButtonWrapper {
        display: flex;
    }

    &-ResendButton {
        min-width: 193px;
        height: 60px;
        padding-inline-start: 18px;
        padding-inline-end: 18px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        background-color: var(--surface-color);
        border: 1px solid var(--stroke-color);
        color: var(--body-content-color);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
    }

    &-TopCloseBtn {
        position: absolute;
        inset-block-start: -12px;
        height: 4px;
        width: 34px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        border-radius: 20px;
        background-color: var(--negative-color);

        @include desktop {
            display: none;
        }
    }

    &-BottomCloseBtn {
        position: absolute;
        inset-block-end: -12px;
        height: 6px;
        width: 134px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        border-radius: 20px;
        background-color: var(--body-content-color);

        @include desktop {
            display: none;
        }
    }

    &-Notification {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        @include desktop {
            height: 348px;
        }
    }

    &-NotificationContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-NotificationSymbol {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: var(--secondary-blue-color);
        margin-block-end: 20px;

        & svg {
            height: 18px;
            width: 24px;
        }
    }

    &-NotificationText {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: var(--body-content-color);
    }
}
