/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/variables.override';
@import '../../../../../../src/style/abstract/button.override';
@import '../../../../../../src/style/abstract/media.override';
@import '../../../../../../src/style/abstract/parts.override';
@import '../../../../../../src/style/abstract/icons.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --left-range-progress: 0;
    --right-range-progress: 0;
    --range-thumb-size: 24px;
    --mobile-filter-inline-padding: 16px;
}

@mixin thumb {
    background: var(--secondary-blue-color);
    border: 6px solid var(--color-white);
    border-radius: 50%;
    cursor: pointer;
    height: var(--range-thumb-size);
    pointer-events: auto;
    width: var(--range-thumb-size);
    box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.15);
    -webkit-appearance: none;
}

.PriceSlider {
    position: relative;
    border-bottom: 1px dashed var(--disabled-color);
    padding-block-end: 32px;

    @include mobile {
        padding-block-start: 32px;
    }


    &-InputWrapper {
        display: flex;
        margin: 30px 0 35px;
        width: 100%;
    }

    &-Separator {
        align-items: center;
        cursor: default;
        display: flex;
        font-size: 19px;
        justify-content: center;
        width: 130px;
    }

    &-Input {
        align-items: center;
        display: flex;
        height: 45px;
        width: 100%;

        &Min,
        &Max {
            --input-padding: 15px 5px 14px;

            border: 1px solid var(--input-border-color);
            cursor: text;
            font-size: 14px;
            font-weight: normal;
            height: 100%;
            pointer-events: auto;
            text-align: center;
            width: 100%;
        }
    }

    &-Wrapper {
        position: relative;
    }

    &-Slider {
        background: var(--stroke-color);
        border-radius: 3px;
        height: 4px;
        position: relative;
        width: calc(100% - var(--range-thumb-size));
        margin-block-end: 0;
        margin-inline-start: calc(var(--range-thumb-size) / 2);
        margin-inline-end: calc(var(--range-thumb-size) / 2);

        &Progress {
            background: var(--secondary-blue-color);
            border-radius: 3px;
            inset-inline-end: var(--right-range-progress);
            inset-inline-start: var(--left-range-progress);
            height: 100%;
            position: absolute;
            inset-block-start: 0;
        }
    }

    &-Range {
        &Input {
            position: absolute;
            width: 100%;
            inset-block-end: 0;
        }

        &Min,
        &Max {
            background: none;
            border: none;
            height: 5px;
            inset-block-start: -4px;
            min-height: auto;
            padding: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;

            &:not(:first-of-type) {
                margin-block-start: 0;
            }

            &:focus {
                border: none;
            }

            &::-webkit-slider-thumb {
                @include thumb;
            }

            &::-moz-range-thumb {
                @include thumb;
            }
        }
    }

    &-ToolTipMin,
    &-ToolTipMax {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--secondary-blue-color);
        display: block;
        position: absolute;

        @include desktop {
            inset-block-end: 14px;
        }
    }

    &-ToolTipMin {
        transform: translate(-50%);
        inset-inline-start: calc(var(--left-range-progress) + var(--mobile-filter-inline-padding));

        @include mobile {
            inset-block-end: 19px;
        }
    }

    &-ToolTipMax {
        inset-inline-end: calc(var(--right-range-progress) + var(--mobile-filter-inline-padding));

        @include desktop {
            transform: translateX(50%);
        }

        @include mobile {
            transform: translateX(50%) translateY(200%);
            inset-block-end: 3px;
        }
    }

    &-MaxPriceMark,
    &-MinPriceMark {
        inset-block-start: 13px;
        position: absolute;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--disabled-color);
    }

    &-MaxPriceMark {
        inset-inline-end: 0;

        @include mobile {
            transform: translateY(-200%);
            inset-block-start: 17px;
        }
    }

    &-MinPriceMark {
        inset-inline-start: 0;
    }

    &-Label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        display: inline-block;
        margin-block-end: 59px;
    }
}

[dir="rtl"] {
    .PriceSlider {
        &-ToolTipMin {
            transform: translate(50%);
            inset-inline-start: var(--left-range-progress);
        }
    
        &-ToolTipMax { 
            @include desktop {
                transform: translateX(-50%);
            }

            @include mobile {
                transform: translateX(-50%) translateY(200%);
            }
        }
    }
}

.ProductConfigurableAttributes {
    &-Expandable {
        border-bottom: 1px dashed var(--disabled-color);

        &>div {
            background-color: transparent;
            border: none;
        }

        &>div:nth-child(2) {
            padding-block-start: 27px;
        }
    }
}
