.CmsPage {
    [data-appearance='collage-centered'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
