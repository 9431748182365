/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/button.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/parts.override';
@import '../../../../../src/style/abstract/icons.override';

.PageBuilderSlider {
    .Slider {
        &-Arrow {
            &_isPrev {
                inset-inline-start: 0;
            }

            &_isNext {
                inset-inline-end: 0;
            }
        }
    }
}
