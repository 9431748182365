/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Image {
    &_isPlaceholder,
    &-Loader {
        &.Image_hasAdditionalLogoOnLoader::after {
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background-image: $logoSkylineMonochrome;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: scale(0.9);
        }
    }
}
