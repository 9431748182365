/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$delieveryIcon: url("data:image/svg+xml,%3Csvg width='61' height='60' viewBox='0 0 61 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.2302 39.633H16.9302V16H41.6212V39.633H33.2042' stroke='black' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M44.5649 21.1152H52.421L59.155 31.2152V39.0712H52.9819' stroke='black' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M49.055 43C51.2243 43 52.983 41.2414 52.983 39.072C52.983 36.9027 51.2243 35.144 49.055 35.144C46.8856 35.144 45.127 36.9027 45.127 39.072C45.127 41.2414 46.8856 43 49.055 43Z' stroke='black' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M28.7464 43C30.9157 43 32.6744 41.2414 32.6744 39.072C32.6744 36.9027 30.9157 35.144 28.7464 35.144C26.577 35.144 24.8184 36.9027 24.8184 39.072C24.8184 41.2414 26.577 43 28.7464 43Z' stroke='black' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M6.59375 31.9658H12.7088' stroke='black' stroke-width='2'/%3E%3Cpath d='M1 36.8149H12.709' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");

.EstimatedDelivery {
    width: 100%;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between          ;
    align-items: center;
    gap: 28px;
    border: 1px solid var(--stroke-color);

    &-Image {
        width: 61px;
        height: 60px;
        background-image: $delieveryIcon;
        flex-shrink: 0;
    }

    &-Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0;
    }

    &-Header {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color)
    }

    &-Value {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $black;
    }
}
