.CreditBureauReportOptions {
    &-ContentWrapper {
        padding-block-start: 24px;
        max-width: 960px;
        margin-inline-start: auto;
        margin-inline-end: auto;

        @include desktop {
            padding-block-start: 60px;
        }
    }

    &-Heading {
        text-transform: none;
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
        margin-block-end: 36px;

        @include desktop {
            font-size: 40px;
            line-height: 52px;
            margin-block-end: 44px;
        }
    }

    &-SubHeading {
        text-transform: none;
        text-align: start;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-block-end: 24px;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
            margin-block-end: 40px;
        }
    }

    &-OptionsWrapper {
        @include desktop {
            display: flex;
            column-gap: 40px;
        }
    }

    &-OptionPod {
        padding: 24px;
        border: 1px solid var(--stroke-color);

        @include desktop {
            width: 460px;
            min-height: 328px;
            padding: 48px;
        }
    }

    &-OptionPod:first-child {
        @include mobile {
            margin-block-end: 20px;
        }
    }

    &-PodHeading {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-block-end: 20px;
        margin-block-start: 0px;
        text-align: center;
    }

    &-PodContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-PodText {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-Download {
        margin-block-start: 20px;

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background-image: url(../icons/download-icon.svg);
            vertical-align: middle;
            margin-inline-start: 4px;
        }
    }
}
