.CmsPage {
    [data-content-type='button-item'] {
        max-width: 100%;

        [data-element='link'],
        [data-element='empty_link'] {
            max-width: 100%;
            word-wrap: break-word;
        }
    }

    a,
    button,
    div {
        &.pagebuilder-button-link {
            @include button;
            @extend .pagebuilder-button-secondary_likeLink;
            box-shadow: none;
            box-sizing: border-box;
            background: none;
            display: inline-block;
        }

        &.pagebuilder-button-primary {
            @include button;
            text-decoration: none;
            box-shadow: none;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        &.pagebuilder-button-secondary {
            @include button;
            box-shadow: none;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}
