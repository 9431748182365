// Overridden to change to mobile view for screen width <= 1240px
@mixin desktop {
    @media (min-width: 1241px) {
        @content;
    }
}

// Overridden to change to mobile view for screen width <= 1240px
@mixin mobile {
    @media (max-width: 1240px) {
        @content;
    }
}

@mixin ultra-wide-desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin super-wide-desktop {
    @media (min-width: 1921px) {
        @content;
    }
}

@mixin iPadMini {
    @media (min-width: 750px) and (max-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: 320px) {
        @content
    }
}
