/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.SummaryIcon {
    path {
        &:not(:last-child) {
            stroke: var(--body-content-color);
            stroke-width: 1px;
        }

        &:last-child {
            fill: var(--body-content-color);
        }

        @include mobile {
            &:not(:nth-last-of-type(-n+2)) {
                stroke-width: 2px;
            }

            &:nth-last-of-type(-n+2) {
                stroke: none;
                fill: var(--body-content-color);
            }
        }
    }
}
