/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.StyleGuidePage {
    &-WideDesktopContentWrapper {
        border: 1px solid var(--primary-base-color);
        padding-inline: 52px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-DesktopContentWrapper {
        max-width: 1024px;
        padding-inline: 52px;
    }

    &-TabletContentWrapper {
        border: 1px solid var(--primary-base-color);
        max-width: 768px;
        padding-inline: 32px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-MobileContentWrapper {
        max-width: 414px;
        padding-inline: 16px;
    }

    &-SmallMobileContentWrapper {
        border: 1px solid var(--primary-base-color);
        max-width: 320px;
        padding-inline: 16px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-Colors {
        #secondaryBlue {
            background-color: var(--secondary-blue-color);
        }

        #secondaryOrange {
            background-color: var(--secondary-orange-color);
        }

        #secondaryPromotion {
            background-color: var(--secondary-promotion-background);
        }

        #secondaryPromotionText {
            background-color: var(--secondary-promotion-text);
        }

        #hover {
            background-color: var(--hover-color);
        }

        #text {
            background-color: var(--body-content-color);
        }

        #disabled {
            background-color: var(--disabled-color);
        }

        #negative {
            background-color: var(--negative-color);
        }

        #stroke {
            background-color: var(--stroke-color);
        }

        #surface {
            background-color: var(--surface-color);
        }

        #background {
            background-color: var(--background-color);
        }
    }

    &-TextStyles {
        display: flex;
        padding: 32px 0;

        div {
            margin-inline-end: 60px;
        }
    }

    &-DesktopTextStyles {
        display: flex;
        justify-content: space-between;
    }

    &-MobileTextStyles {
        h1 {
            line-height: var(--h1-line-height-mobile);
        }

        h2 {
            line-height: var(--h2-line-height-mobile);
        }

        h3 {
            line-height: var(--h3-line-height-mobile);
        }
    }

    .react-datepicker {
        &__input-container {
            input {
                width: 100%;
                max-width: 400px;
            }
        }
    }

    &-Buttons {
        display: flex;
        align-items: center;

        div {
            display: flex;
            flex-direction: column;
            margin-block-end: 24px;

            button {
                margin-block-end: 24px;
            }
        }
    }

    .Button {
        &_isHovered {
            &.Button_isHollow {
                border-color: var(--primary-base-color);
                background-color: var(--primary-base-color);
                color: var(--color-white);

                svg {
                    fill: var(--color-white);
                }
            }

            &.Button_likeLink {
                color: var(--text-like-button-hover);
                text-decoration: underline;
                text-underline-position: under;
            }
        }
    }

    &-TextButtons {
        display: flex;

        h4 {
            margin-inline-end: 20px;
        }

        .Button {
            display: flex;
            margin-block-end: 24px;

            &_isHovered {
                svg {
                    fill: var(--link-hover);
                }
            }
        }
    }

    &-HoveredInput#hoveredCheckbox ~ .input-control, &-HoveredInput#hoveredRadio ~ .input-control {
        @include desktop {
            border-color: var(--secondary-blue-color);

            &::after {
                @include desktop {
                    --checkmark-color: var(--color-white);
                }
            }
        }
    }

    &-ActiveLink {
        border-block-end: 0;
        text-decoration: underline;
    }
}
