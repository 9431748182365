/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --notification-success-background: var(--imported_notificationSuccess_backgroud_color, var(--secondary-blue-color));
    --notification-error-backgroud: var(--imported_notificationError_backgroud_color, var(--primary-error-color));
    --notification-info-background: var(--imported_notificationInfo_backgroud_color, var(--secondary-orange-color));
}
