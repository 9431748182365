@mixin button-disabled {
    opacity: 1;
}

@mixin Button {
    font-size: 22px;
    font-weight: 700;
    text-transform: none;

    svg {
        fill: var(--color-white);
        margin-inline-start: 8px;
    }

    &[disabled] {
        @include button-disabled();

        background: var(--disabled-color);
        color: var(--negative-color);
        border-color: var(--disabled-color);

        svg {
            fill: var(--negative-color);
        }
    }

    &_isHollow {
        svg {
            pointer-events: none;
            fill: var(--disabled-color);
        }

        &:not([disabled]) {
            &:hover {
                svg {
                    fill: var(--color-white);
                }
            }
        }
    }

    &_likeText {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--text-like-button-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--text-like-button-hover);
        --button-height: min-content;
        --button-hover-height: min-content;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        svg {
            fill: var(--button-color);
        }

        &:not([disabled]) {
            &:hover {
                svg {
                    @include desktop {
                        fill: var(--button-hover-color);
                    }
                }
            }
        }

        &:disabled {
            background: transparent;
            color: var(--disabled-color);
            border-color: transparent;

            svg {
                fill: var(--disabled-color);
            }
        }
    }

    &_likeLink {
        --button-hover-color: var(--text-like-button-hover);

        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-base-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    text-underline-position: under;
                }

                svg {
                    @include desktop {
                        fill: var(--text-like-button-hover);
                    }
                }
            }

            svg {
                fill: var(--primary-base-color);
            }
        }

        &:disabled {
            background: transparent;
            color: var(--disabled-color);
            border-color: transparent;

            &:hover {
                text-decoration: none;
            }

            svg {
                fill: var(--disabled-color);
            }
        }
    }
}
