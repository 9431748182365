/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.DealershipChooser {
    &-Select {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 24px;
        margin-block-end: 20px;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            margin-block-end: 24px;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_text { /* visually hiding a text element to keep data for form collector */
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        padding: 0;
                        white-space: nowrap;
                        clip-path: inset(100%);
                        clip: rect(0 0 0 0);
                        overflow: hidden;
                    }
                }
            }
        }
    }

    &-Main {
        min-height: 410px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 36px;
        column-gap: 24px;
        padding: 24px 20px;
        border: 1px solid var(--stroke-color);
        box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.08);

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
            box-shadow: none;
        }
    }

    &-Map {
        .leaflet-container {
            z-index: 0;
            min-height: 360px;
        }
    }

    &-Content {
        @include desktop {
            padding-inline-end: 20px;
        }

        h3 {
            margin-block-start: 0;
        }

        dl {
            padding-block-start: 16px;
            border-top: 1px dashed var(--separator-text-color);
        }

        dt {
            &::before {
                content: '•';
                display: inline-block;
                margin-inline-end: 10px;
            }
        }

        dd {
            margin-block-end: 16px;

            address {
                font-weight: 700;
                font-style: normal;
            }

            a {
                color: var(--color-black);

                &:hover,
                &:focus {
                    color: var(--link-hover);
                }
            }

            p {
                margin: 0;
                font-weight: 700;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }
}
