.HowItWorks {
    &-Wrapper {
        max-width: var(--content-wrapper-width);
        padding: 0 52px;
        background: #{$surface-color};

        @include mobile {
            margin-block-end: 80px;
            padding: 0 16px;
            padding-block-end: 48px;
        }

        @include desktop {
            margin: 0 calc(50vw - var(--content-wrapper-width) * 0.5);
            margin-block-end: 108px;
            padding-block-end: 60px;
        }
    }

    p, li {
        font-size: 20px;
        line-height: 25px;
    }

    &-ContentWrapper {
        background: #{$surface-color};

        @include mobile {
            margin: 0 calc(16px * -1);
        }

        @include desktop {
            margin: 0 calc(52px * -1 - 50vw + var(--content-wrapper-width) * 0.5);
        }
    }

    &-Accordion {
        @include mobile {
            .HowItWorks-AccordionHeading {
                border-bottom: 1px dotted $primary-disabled-color;
            }

            &:last-child {
                .HowItWorks-AccordionHeading {
                    border-bottom: none;
                }
            }
        }
    }

    &-Heading {
        text-align: center;
        text-transform: unset;
        margin-block-end: 36px;

        @include mobile {
            padding-block-start: 48px;
        }

        @include desktop {
            padding-block-start: 60px;
            margin-block-start: 16px;
        }
    }

    &-AccordionWrapper {
        @include desktop {
            display: flex;
            flex-direction: row;
        }

        & .AccordionTabs-ContentWrapper {
            @include desktop {
                width: 948px;
            }
        }
    }

    &-AccordionContent {
        background: $white;

        @include desktop {
            margin-inline-start: 8px;
            padding-inline-start: 75px;
            padding-inline-end: 50px;
            padding-block-start: 48px;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;
        }
    }

    &-AccordionText {
        @include desktop {
            margin-block-start: auto;
            margin-block-end: auto;
        }

        @include mobile {
            padding-block-end: 40px;
            padding-inline-start: 16px;
            margin-block-end: 0;
        }

        & + ul {
            /* stylelint-disable-next-line declaration-no-important */
            margin: 0 !important;
        }
    }

    &-AccordionSubHeading {
        margin-block-end: 0;
        
        @include mobile {
            padding-inline-start: 16px;
            margin-block-end: 40px;
            font-size: 22px;
            line-height: 28px;
        }
    }

    h2 {
        margin-block-start: 0;

        @include mobile {
            text-align: start;
        }
    }

    &-AccordionHeading {
        background: var(--button-background);
        color: $white;
        font-size: 16px;
        line-height: 24px;

        &:hover {
            cursor: pointer;
        }

        @include desktop {
            padding: 28px;
            min-width: 380px;
            border-bottom: 1px dotted $primary-disabled-color;

            &:last-child {
                border-bottom: none;
            }
        }

        b {
            padding-inline-end: 15px;
        }

        @include mobile {
            min-height: 60px;
            display: flex;
            padding-inline-start: 16px;
            flex-direction: column;
            justify-content: center;
        }
    }

    .AccordionTabs {
        &-TabsWrapper {
            @include desktop {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
            }
        }

        &-TabName {
            @include desktop {
                height: 100%;
                display: flex;
                align-items: center;
            }

            @include mobile {
                padding: 24px 16px;
            }

            &_isSelected {
                color: var(--button-background);
                background: $white;

                @include mobile {
                    align-items: center;
                    flex-direction: row;
                    justify-content: unset;
                    color: $black;
                    border-bottom: none;

                    .HowItWorks-Hide {
                        display: none;
                    }
                }
            }

            @include desktop {
                &::after {
                    display: none;
                }
            }
        }

        &-TabContent {
            &_isVisible {
                @include desktop {
                    display: flex;
                }
            }
        }
    }
}
