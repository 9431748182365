/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.PasswordChangePage {
    margin-block-start: var(--header-height);

    .FieldForm-Fields {
        margin-block-end: 20px;
    }

    .Field {
        &-ErrorMessages {
            margin: 12px 0;
        }
    }

    &-Wrapper {
        max-width: 560px;

        .Button {
            width: 100%;
            margin-block-start: 20px;
            font-size: 16px;
            text-transform: capitalize;
        }

        h1 {
            margin: 0 10px 0 0;

            @include mobile {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }

    &-WrapperHeader {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include desktop {
            margin: 0 0 -36px;
        }

        .RequiredMark {
            display: flex;
            align-items: center;

            p {
                margin-block-end: 0;
                font-size: 14px;
                line-height: 20px;
            }

            span {
                padding: 0 0 0 3px;
            }
        }
    }

    &-PasswordMarkWrapper {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        row-gap: 16px;
        justify-content: space-between;

        .RequiredMark {
            @include desktop {
                visibility: hidden;
            }
        }
    }

    &-InfoMessage {
        margin-block-start: 36px;
        margin-block-end: 4px;
        padding-block-end: 24px;
        border-block-end: 1px dashed var(--disabled-color);
        width: 100%;

        @include desktop {
            margin-block-start: 44px;
            margin-block-end: 36px;
            padding-block-end: 32px;
        }

        + .RequiredMark {
            margin: 0 0 0 auto;

            @include mobile {
                display: none;
            }
        }
    }

    .ContentWrapper {
        padding-block: 24px 48px;
        padding-inline: 0;

        @include desktop {
            padding-block: 60px 108px;
        }

        @include mobile {
            padding-inline: 16px;
        }
    }
}
