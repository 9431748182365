@mixin scrollbar($widthMobile: 4px, $widthDesktop: 8px) {
    &::-webkit-scrollbar {
        width: $widthMobile;

        @include desktop {
            width: $widthDesktop;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: var(--negative-color);
    }

    &::-webkit-scrollbar-track {
        background: var(--surface-color);
        border-left: 1px solid #D9D9D9;
    }
}

@mixin linkWithArrow {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(3px);
    margin: 0;
    margin-inline-start: 10px;

    &::after {
        content: '';
        height: 6px;
        width: 6px;
        border: 2px solid var(--primary-base-color);
        border-top: initial;
        border-left: initial;
        transform: rotate(-45deg);
        margin: 0;
        margin-block-end: 4px;
        margin-inline-start: 7px;

        &:dir(rtl) {
            transform: rotate(135deg);
        }
    }

    &:hover::after {
        border-color: var(--link-hover);
    }
}

