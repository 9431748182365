/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CheckoutBilling {
    &-Wrapper {
        ~ h1 {
            margin-block-end: 0;
        }

        .CheckoutBilling-Button,
        .CheckoutBilling-ButtonPrevious {
            width: 100%;

            @include desktop {
                width: 300px;
            }
        }
    }

    &-Options {
        display: flex;
        flex-direction: column;
        gap: 16px 0;

        @include desktop {
            flex-direction: row;
            gap: 0 24px;
        }
    }

    &-Option {
        flex: 1;
        border: 1px solid var(--stroke-color);
        margin: 1px;
        transition: border-color 300ms ease-in-out;

        @include desktop {
            max-width: 295px;
        }

        &_isChecked {
            margin: 0;
            border: 2px solid var(--secondary-blue-color);
        }

        &Label {
            font-size: 16px;
            font-weight: 700;
            margin: 0 0 12px;
            line-height: 24px;
        }

        .Field {
            margin: 0;
        }

        label {
            width: 100%;
            padding: 24px 0 24px 20px;

            [dir="rtl"] & {
                padding: 24px 20px 24px 0px;
            }
        }

        input[type='radio'] {
            width: 100%;
            height: 100%;
            margin-inline-start: -20px;
        }
    }

    &-AdditionalField {
        margin: 0 0 20px;

        @include desktop {
            margin: 22px 0 0;
        }

        .Field-Wrapper:first-child {
            .Field {
                margin: 0;
            }
        }
    }

    &-Total {
        margin: 0 0 32px;
        text-transform: none;

        @include desktop {
            margin: 12px 0 44px;
        }
    }

    &-Amount {
        margin-inline-start: 6px;
    }

    &-Paragraph {
        margin: 24px 0 36px;
    }

    &-IhfOption-Wrapper {
        .CheckoutBilling-Form {
            display: flex;
            flex-flow: column nowrap;
            gap: 0;

            @include desktop {
                gap: 0 12px;
                flex-flow: row wrap;
            }
        }
    }

    &-BankOption {
        &-Wrapper {
            .Field {
                margin: 12px 0 0;
            }

            .Field-Wrapper {
                margin: 0 0 40px;

                @include desktop {
                    width: 300px;
                }
            }
        }

        &-Payment {
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
                gap: 20px;
            }

            .Field-Wrapper {
                flex-grow: 1;
                margin: 0 0 44px;

                .Field {
                    margin: 0;

                    input {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-CashOption-Wrapper {
        .Field {
            margin: 0;
        }

        .FieldGroup {
            &-Wrapper {
                margin: 0 0 40px;

                @include desktop {
                    margin: 0 0 44px;
                }
            }

            &_hasError,
            &_isValid {
                border: none;
                padding: 0;
            }

            display: flex;
            gap: 16px 0;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
                gap: 0 48px;
            }
        }
    }

    &-FormTab.CheckoutBilling-DocumentTab {
        .Field-Wrapper {
            @include desktop {
                width: 100%;
            }
        }
    }

    &-Note {
        font-size: 16px;
        line-height: 24px;
    }

    &-Tooltip {
        &-Wrapper {
            margin: 0 auto;
            width: 288px;

            @include desktop {
                width: 240px;
                margin-block-start: 8px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 12px 0 10px;
            }
        }

        &-Text {
            font-size: 22px;
            line-height: 28px;
            font-weight: 700;
            text-align: center;

            @include desktop {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    &-FormTab {
        padding: 24px 16px;

        @include desktop {
            padding: 32px;
        }

        .Field-Wrapper {
            width: 100%;

            @include desktop {
                width: calc(50% - 6px);
            }
        }

        .Field input {
            width: 100%;
        }

        .FieldDate-Calendar {
            margin-inline-start: -1px;

            @include desktop {
                margin: 0;
            }
        }
    }

    &-ActionBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 40px 0 0;

        @include desktop {
            flex-direction: row;
        }

        &_isCentered {
            justify-content: center;
        }

        .CheckoutBilling-ButtonPrevious {
            margin: 0 0 20px;
            background-color: var(--negative-color);
            border-color: var(--negative-color);

            &:hover {
                background-color: var(--negative-color-hover);
                border-color: var(--negative-color-hover);
            }

            @include desktop {
                margin: 0 20px 0 0;

                [dir="rtl"] & {
                    margin: 0 0px 0 20px
                }
            }

            svg {
                margin-inline-start: 0;
                margin-inline-end: 8px;
            }
        }
    }

    &-Nationality, &-Salutation {
        margin: 0 10px 0 0;

        .CheckoutBilling-Nationality,
        .CheckoutBilling-Salutation {
            margin: 36px 0 0;
        }
    }

    &-RequiredFields {
        width: 100%;
        font-size: 14px;

        &-Asterisk {
            color: var(--secondary-blue-color);
            margin-inline-start: 3px;
        }
    }
}
