/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ExpandableTabGroup {
    &-Tab {
        border: 1px solid var(--stroke-color);
        border-block-end: none;

        &:last-child {
            border-block-end: 1px solid var(--stroke-color);
        }
    }

    .ExpandableContent {
        &-Heading {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;

            @include desktop {
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
            }
        }

        &-Button {
            padding: 24px 16px;
            background-color: var(--surface-color);
            cursor: pointer;

            @include desktop {
                padding: 24px 32px;
            }
        }

        &-Content {
            margin: 0;
            border: none;
            background-color: var(--background-color);
            display: none;

            &_isContentExpanded {
                display: block;
            }
        }
    }
}
