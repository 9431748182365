/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CmsPage {
    margin-block-start: calc(var(--header-nav-height) + var(--breadcrumbs-height)) ;

    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-height) - 31px) ;
    }

    &-Wrapper {
        @include desktop {
            padding-inline: 52px;
        }

        @include tablet {
            padding-inline: 32px;
        }

        @include mobile {
            padding-inline: 16px;
            margin-block-start: calc(var(--header-height));
        }
    }

    &-Heading {
        margin-block-start: calc(var(--header-height) + 31px);

        @include mobile {
            display: block;
        }
    }

    &-Content {
        overflow-x: visible;
    }

    .widget {
        overflow-x: unset;
    }

    h3 {
        @include mobile {
            font-size: 16px;
        }
    }
}
