.OurCarBuyingExperience {
    &-Wrapper {
        @include mobile {
            margin-block-end: 80px;
        }

        @include desktop {
            margin-block-end: 108px;
        }
    }

    &-Heading {
        text-align: center;
        text-transform: unset;
        margin-block-end: 36px;

        @include desktop {
            margin-block-start: 16px;
        }
    }

    &-Text {
        text-align: center;
        margin: auto;
        margin-block-end: 24px;
        font-size: 16px;
        line-height: 24px;

        @include desktop {
            max-width: 600px;
        }
    }

    &-GetStarted {
        @include button;

        text-align: center;

        @include desktop {
            max-width: 348px;
            width: 100%;
        }

        h3 {
            color: $white;

            @include mobile {
                /* stylelint-disable declaration-no-important */
                font-size: 22px !important;
                line-height: 28px !important;
                /* stylelint-enable declaration-no-important */
            }
        }
    }

    &-LinkWrapper {
        text-align: center;
    }
}
