/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$leftBlockMobileHeight: 410px;
$cardsGap: 96px;
$cardsGapMobile: 64px;
$cardHeight: 280px;

.SuccessStep {
    &-TopBackground {
        position: relative;
        background-image: linear-gradient(90deg, var(--surface-color) 50%, $white 50%);

        [dir="rtl"] & {
            background-image: linear-gradient(270deg, var(--surface-color) 50%, $white 50%);
        }

        @include mobile {
            background-image: none;
        }
    }

    &-MobileBackground {
        position: absolute;
        inset-inline-start: 0;
        width: 100%;
        height: $leftBlockMobileHeight;
        background-color: var(--surface-color);
    }

    &-TopBlock {
        position: relative;
        height: 760px;
        display: flex;

        @include mobile {
            display: block;
            height: auto;
        }
    }

    &-LeftBlock {
        flex-basis: 50%;

        @include mobile {
            height: $leftBlockMobileHeight;
            padding: 48px 0 28px;
        }
    }

    &-RightBlock {
        flex-basis: 50%;
        padding: 90px 0 80px 120px;

        [dir="rtl"] & {
            padding: 90px 120px 80px 0;

            @include mobile {
                padding: 40px 0 60px;
            }
        }

        @include mobile {
            padding: 40px 0 60px;
        }
    }

    &-ImageBlock {
        width: 668px;
        height: 508px;
        margin: 112px 0 140px;
        position: absolute;
        inset-inline-end: -43px;

        @include mobile {
            width: 100%;
            height: 248px;
            margin: 0;
            position: relative;
            inset-inline-end: 0;
        }
    }

    &-Image {
        width: 100%;
        height: 444px;

        @include mobile {
            height: auto;
        }
    }

    &-ImageHeaderBlock {
        display: flex;
        margin-block-start: 12px;
        align-items: center;
        justify-content: center;
        gap: 40px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }
    }

    &-ImageSubHeading {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--disabled-color);
    }

    &-ImageHeading {
        margin: 4px 0 0;
        font-family: $font-HyundaiSansHeadKR;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: $black;

        &_isQuotNumber {
            color: var(--secondary-blue-color);
        }
    }

    &-VehicleIcon {
        width: 90px;
        height: 39px;
        background-image: $vehicleIcon;
        margin-block-end: 28px;

        @include mobile {
            transform: scale(0.7);
            transform-origin: left top;
            margin-block-end: 0;
        }
    }

    &-ThankYou {
        margin-block-end: 32px;

        p {
            font-family: $font-HyundaiSansHeadKR;
            font-size: 40px;
            font-weight: 700;
            line-height: 52px;
            margin: 0;

            @include mobile {
                font-size: 26px;
                line-height: 32px;
            }
        }
    }

    &-Note {
        padding-block-end: 20px;
        border-block-end: 3px solid $black;
        margin-block-end: 32px;

        p {
            margin: 0;

            &:not(:last-child) {
                margin-block-end: 8px;
            }

            a {
                border-block-end: 1px solid var(--primary-base-color);
            }
        }
    }

    &-UserEmail {
        font-weight: 700;
        color: var(--secondary-blue-color);
    }

    .PaymentInfo {
        &-Header {
            margin: 0;
            font-family: $font-HyundaiSansHeadKR;
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            margin-block-end: 20px;

            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-ListItem {
            display: flex;
            justify-content: space-between;
            margin: 0 0 8px 0;
            padding-inline-start: 14px;

            &::before {
                inset-inline-start: 0;
            }

            @include mobile {
                flex-direction: column;
            }
        }

        &-Value {
            text-transform: capitalize;
        }
    }

    &-ImageHeaderQuote {
        @include mobile {
            order: 1;
        }
    }

    &-BottomBlock {
        margin: 108px 0;

        @include mobile {
            margin: 0 0 48px;
        }
    }

    &-BottomHeading {
        margin: 0 0 36px;
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        text-transform: none;

        @include mobile {
            font-size: 26px;
            line-height: 32px;
            margin-block-end: 32px;
        }
    }

    &-BottomSubHeading {
        margin: 0 0 12px;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        text-transform: none;

        @include mobile {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &-Cards {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $cardsGap;
        margin-block-end: 60px;

        @include mobile {
            flex-direction: column;
            gap: 64px;
            margin-block-end: 40px;
        }
    }

    &-Card {
        position: relative;
        flex: 1;
        border: 1px solid var(--stroke-color);
        height: $cardHeight;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile {
            flex-basis: $cardHeight;
        }

        &:not(:last-child)::after {
            content: '';
            width: 17px;
            height: 30px;
            background-image: $chevronBigIcon;
            position: absolute;
            inset-inline-end: calc(-#{$cardsGap} / 2 - 30px / 2);
            inset-block-start: calc(#{$cardHeight} / 2 - 17px / 2);

            @include mobile {
                inset-block-end: calc(-#{$cardsGapMobile} / 2 - 30px / 2);
                inset-block-start: unset;
                inset-inline-end: calc(50% - 17px / 2);
                transform: rotate(90deg);
                transform-origin: center;
            }
        }
    }

    &-Icon {
        width: 60px;
        height: 60px;

        &_icon_person {
            background-image: $dealerIcon;
        }

        &_icon_payment {
            background-image: $iconPayment;
        }

        &_icon_handle {
            background-image: $iconHandle;
        }

        &_icon_download {
            background-image: $iconDownload;
            width: 20px;
            height: 20px;
            margin-inline-start: 10px;
        }
    }

    &-CardMessage {
        margin-block-start: 20px;
        max-width: 287px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
    }

    &-CtaButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;

        @include mobile {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &-Button {
        width: 348px;

        @include mobile {
            width: 100%;
        }

        &_color_secondary {
            background-color: var(--negative-color);
            border-color: var(--negative-color);

            &.Button:hover, &:active {
                background-color: var(--negative-color-hover);
                border-color: var(--negative-color-hover);
            }
        }
    }
}
