/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Tabs {
    display: block;

    &-List {
        margin: 0 auto 30px;
        width: 358px;
        display: flex;
        gap: 4px;
        background-color: transparent;
        border-block-end: none;
        z-index: 9;

        // JAID-196 changing tabs styles for interior pictures

        &_isInterior {
            .Tab-Item {
                &_isActive {
                    @include desktop {
                        .Tab-Button {
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 28px;
                        }
                    }
                }
            }
        }

        @include mobile {
            position: fixed;
            inset-block-start: 100px;
            z-index: 4;
            gap: 0;
            width: 100%;
            background-color: $white;
            border-block-end: 1px solid var(--stroke-color);
        }

        & > * {
            flex: 1;
        }
    }

    &-Wrapper {
        @include mobile {
            padding: 60px 0 0 0;
        }
    }
}
