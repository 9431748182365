/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.SummaryStep {
    &-Heading {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        margin-block-end: 44px;

        @include mobile {
            display: none;
        }
    }

    &-ListsWrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 400px 400px;
            row-gap: 40px;
            column-gap: 40px;
        }
    }

    &-ListWrapper {
        @include mobile {
            margin-block-end: 28px;
        }
    }

    &-IhfTermsListWrapper {
        @include mobile {
            margin-block-end: 28px;
        }

        @include desktop {
            grid-column: 1/2;
        }
    }

    &-ListHeading {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-block-start: 0;
        margin-block-end: 16px;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
            margin-block-end: 28px;
        }
    }

    &-InfoNotice {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--disabled-color);
        margin-block-start: 44px;
        margin-block-end: 12px;
        max-width: 900px;

        a {
            font-weight: 700;
        }

        p {
            margin: 0;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }

    &-Button {
        min-width: 308px;
    }

    &-ListItem {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        list-style: circle inside;

        @include desktop {
            width: 400px;
        }

        @include mobile {
            padding-inline-start: 14px;
            flex-direction: column;
        }

        &>span {
            display: inline-flex;
            align-items: flex-start;
        }

        &>span:first-child {
            // display: inline-block;
            font-weight: 700;

            @include desktop {
                width: 185px;
                margin-inline-end: 35px;
            }
        }

        &>span:last-child {
            display: inline-block;

            @include desktop {
                width: 180px;
            }
        }
    }

    &-ListItem:not(:last-child) {
        margin-block-end: 12px;

        @include desktop {
            margin-block-end: 8px;
        }
    }

    &-ListItem:last-child {
        margin-block-end: 0px;
    }

    &-ListItem::before {
        inset-inline-start: 0px;

        @include desktop {
            display: none;
        }
    }

    &-TooltipButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &-TooltipButtonWrapper {
        width: 21px;
        height: 21px;
        margin-inline-start: 12px;
        padding-block-start: 1px;

        @include desktop {
            position: relative;
            margin-inline-start: auto;
        }
    }

    &-Popup {
        & .Popup-Content {
            @include mobile {
                margin-block-start: auto;
                border-radius: 10px 10px 0px 0px;
                box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
                height: 320px;
            }

            @include small-mobile {
                height: 280px;
            }
        }

        & .Popup-ChildrenWrapper {
            @include desktop {
                padding-block-start: 36px;
                padding-block-end: 60px;
            }

            @include mobile {
                height: 100%;
            }
        }

        &TopCloseBtn {
            position: absolute;
            inset-block-start: -12px;
            height: 4px;
            width: 34px;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            border-radius: 20px;
            background-color: var(--negative-color);
        }

        &BottomCloseBtn {
            position: absolute;
            inset-block-end: -12px;
            height: 6px;
            width: 134px;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            border-radius: 20px;
            background-color: var(--body-content-color);
        }
    }

    &-TooltipContent {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;

        @include desktop {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            padding: 36px 33px 24px 33px;
        }
    }

    &-TooltipContentText {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-DesktopTooltipWrapper {
        position: absolute;
        inset-block-end: 6px;
        inset-inline-start: 3px;
        z-index: 2;

        & svg {
            height: 204px;
            width: 272px;
        }
    }

    &-TooltipCloseButton {
        position: absolute;
        inset-block-start: 17px;
        inset-inline-end: 17px;

        & svg {
            height: 10px;
            width: 10px;
        }
    }
}
