$primary-text-color: #000;
$primary-disabled-color: #999999;
$error-color: #e80710;
$negative-color: #cccccc;
$stroke-color: #d9d9d9;
$surface-color: #f4f4f4;
$secondary-blue-color: #0559f7;
$secondary-orange-color: #ff9934;
$secondary-promotion-background: #fbe4e8;
$secondary-promotion-text: #ff536e;
$default-hover-color: #0b2451;
$separator-text-color: #999999;
$negativeColorHover: #b3b3b3;

:root {
    --hover-color: #{$default-hover-color};
    --body-content-color: #{$primary-text-color};
    --secondary-blue-color: #{$secondary-blue-color};
    --secondary-orange-color: #{$secondary-orange-color};
    --secondary-promotion-background: #{$secondary-promotion-background};
    --secondary-promotion-text: #{$secondary-promotion-text};
    --disabled-color: #{$primary-disabled-color};
    --primary-error-color: #{$error-color};
    --background-color: #{$white};
    --surface-color: #{$surface-color};
    --stroke-color: #{$stroke-color};
    --negative-color: #{$negative-color};
    --separator-text-color: #{$separator-text-color};
    --negative-color-hover: #{$negativeColorHover};
}
