@font-face { // Light
    font-family: 'HyundaiSansHeadKR';
    src: url(../fonts/HyundaiSansHeadKRLight.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face { // Regular
    font-family: 'HyundaiSansHeadKR';
    src: url(../fonts/HyundaiSansHeadKRRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face { // Medium
    font-family: 'HyundaiSansHeadKR';
    src: url(../fonts/HyundaiSansHeadKRMedium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face { // Bold
    font-family: 'HyundaiSansHeadKR';
    src: url(../fonts/HyundaiSansHeadKRBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face { // Regular
    font-family: 'HyundaiSansTextKR';
    src: url(../fonts/HyundaiSansTextKRRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face { // Medium
    font-family: 'HyundaiSansTextKR';
    src: url(../fonts/HyundaiSansTextKRMedium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face { // Bold
    font-family: 'HyundaiSansTextKR';
    src: url(../fonts/HyundaiSansTextKRBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
