/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--stroke-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--secondary-blue-color));
}

.Field {
    margin-block-start: 28px;

    @include desktop {
        margin-block-start: 36px;
    }

    input {
        padding: 12px 20px;
    }

    &-Wrapper {
        &_type_file,
        &_type_image {
            .Field {
                margin-block-start: 28px;
            }

            + .Field-Wrapper_type_file,
            + .Field-Wrapper_type_image {
                .Field {
                    margin-block-start: 20px;
                }
            }
        }
    }

    &-CheckboxLabel, &-RadioLabel {
        input {
            font-size: 16px;
        }

        span {
            display: inline-flex;
            align-items: center;
            font-size: 16px;
        }

        &:hover {
            @include desktop {
                cursor: pointer;
                color: var(--body-content-color);
            }
        }
    }

    &-ErrorMessages {
        font-size: 14px;
        line-height: 20px;
    }

    [type='checkbox'], [type='radio'] {  
        +.input-control {
            border: 1px solid var(--checkbox-border-color);
            color: var(--checkbox-border-color);
            margin-inline-end: 8px;
        }

        &:disabled {
            & ~ span {
                color: var(--disabled-color);
            }
        }

        &:disabled + .input-control {
            background-color: var(--surface-color);
            color: var(--disabled-color);
            cursor: auto;
            opacity: .7;
        }

        &:hover {
            &:disabled + .input-control {
                @include desktop {
                    border-color: var(--checkbox-border-color);
                    color: var(--disabled-color);
                    cursor: auto;
                }
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: none;
            }
        }

        &:hover + .input-control {
            @include desktop {
                border-color: var(--checkbox-border-color-active);
                cursor: pointer;
            }
        }

        .RadioIcon path {
            --checkmark-color: transparent;
        }

        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color-active);
                background-color: var(--color-white);

                .RadioIcon {
                    --checkmark-color: var(--secondary-blue-color);
                }
            }

            &:hover + .input-control {
                @include desktop {
                    background-color: $white;
                    border-color: var(--checkbox-border-color-active);
                }
            }

            &:disabled {
                &:hover + .input-control {
                    @include desktop {
                        background-color: var(--surface-color);
                        border-color: var(--checkbox-border-color);
                    }
                }

                + .input-control {
                    border-color: var(--checkbox-border-color);
                    background-color: var(--surface-color);
                    cursor: auto;
                    opacity: .7;

                    .RadioIcon path {
                        --checkmark-color: var(--checkbox-border-color);
                    }
                }
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: none;
            }
        }

        .RadioIcon path {
            --checkmark-color: transparent;
        }

        &:hover + .input-control {
            @include desktop {
                border-color: var(--checkbox-border-color-active);
                cursor: pointer;
            }
        }

        &:checked {
            + .input-control {
                border: 1px solid var(--checkbox-border-color-active);
                background-color: var(--checkbox-border-color-active);

                .RadioIcon {
                    --checkmark-color: var(--color-white);
                }
            }

            &:hover + .input-control {
                @include desktop {
                    border-color: var(--checkbox-border-color-active);
                }
            }

            &:disabled {
                + .input-control {
                    border: 1px solid var(--checkbox-border-color);
                    background-color: var(--surface-color);

                    .RadioIcon path {
                        --checkmark-color: var(--checkbox-border-color);
                    }
                }
            }
        }
    }

    .RadioIcon path {
        fill: var(--checkmark-color);
    }

    &-Label {
        font-size: 16px;
        line-height: 24px;
        padding-block-end: 12px;
        font-weight: 700;

        &_isRequired {
            color: var(--secondary-blue-color);
        }
    }

    &-Error {
        &Message {
            font-size: 14px;
            line-height: 20px;
        }
    }

    input, select {
        font-size: 16px;
        height: 48px;

        &::placeholder {
            color: var(--disabled-color);
        }

        &:disabled {
            background-color: var(--surface-color);
            opacity: 1;
        }
    }

    .ErrorIcon {
        position: absolute;
        inset-inline-end: 10px;
        inset-block-end: 8px;
    }

    &-CapsNotification {
        font-size: 14px;
        position: absolute;
        inset-block-start: 10px;
        inset-inline-start: 50%;
        color: var(--primary-error-color);
        background-color: var(--color-white);
        border: 1px solid var(--stroke-color);
        padding: 2px;
        z-index: 1;
        box-shadow: 0 2px 5px var(--stroke-color);

        .ErrorIcon {
            position: relative;
            inset-inline-end: -4px;
            inset-block-end: -6px;
        }

        &Text {
            padding: 4px;
            padding-inline-end: 10px;
            inset-block-end: 6px;
        }
    }

    &-Triangle {
        position: absolute;
        inset-block-end: -5px;
        inset-inline-start: 5px;
        width: 0;
        height: 0;
        border-inline-end: 10px solid transparent;
        border-block-start: 10px solid var(--color-white);
        z-index: 120;
    }

    &_type {
        &_checkbox,
        &_radio {
            margin-block-start: 22px;
        }

        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border-color: var(--stroke-color);

                    &:focus {
                        border-color: unset;
                    }
                }
            }
        }

        &_file,
        &_image {
            .FileError {
                &-Wrapper {
                    z-index: 3;
                    position: absolute;
                    padding: 9px;
                    background-color: var(--tooltip-background-color);
                    border: 1px solid var(--stroke-color);
                    inset-block-start: -52px;
                    inset-inline-end: 0;
                    min-width: max-content;

                    @include desktop {
                        inset-inline-end: 91%;
                        inset-block-start: 6px;
                    }

                    @include small-mobile {
                        inset-inline-end: -9px;
                    }

                    &::before {
                        content: " ";
                        inset-inline-end: 6px;
                        border: solid transparent;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-width: 4px;
                        margin-inline-start: -4px;
                        inset-block-start: 100%;
                        border-top-color: var(--stroke-color);
                        border-right-color: var(--stroke-color);
                    }

                    &::after {
                        content: " ";
                        inset-inline-end: 7px;
                        border: solid transparent;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-width: 3px;
                        margin-inline-start: -3px;
                        inset-block-start: 100%;
                        border-top-color: var(--tooltip-background-color);
                        border-right-color: var(--tooltip-background-color);
                    }
                }

                &-Item {
                    display: flex;
                    justify-content: center;

                    .ErrorIcon {
                        position: relative;
                        transform: scale(1.5);
                        width: 16px;
                        height: 20px;
                        inset-block-start: 4px;
                        inset-inline-start: 4px;
                    }
                }

                &-Text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--primary-error-color);
                    padding-inline-start: 4px;
                }
            }
        }
    }

    &-SubLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-block-start: 12px;
    }

    &-SubLabelText {
        margin-block-start: 12px;

        .MyAccountOverlay-ForgotPassword {
            margin: 0;
        }
    }

    &-ShowPassword {
        position: absolute;
        inset-block-start: 36px;
        inset-inline-end: 9px;
        padding: 15px 12px 11px;
        display: flex;
        cursor: pointer;
    }

    .FieldFile {
        &-FileWrapper {
            border: 1px solid var(--input-border-color);
            padding: 20px;
        }

        // Browse button style start

        &-BrowseButton {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid var(--input-border-color);
            background-color: var(--surface-color);
            height: 60px;

            @include desktop {
                width: 150px;
            }

            @include mobile {
                width: 100%;
            }
        }

        &-Top {
            display: flex;

            @include mobile {
                padding: 0 0 20px;
            }
        }

        &-TextWrapper {
            flex-grow: 1;
            display: flex;
            gap: 12px;
            justify-content: center;
            flex-direction: column;
        }

        &-ButtonText {
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

        // Browse button style end

        &-LabelText {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
        }

        &-SubLabelText {
            font-size: 14px;
            color: var(--disabled-color);
            line-height: 20px;

            p {
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                margin-block-end: 0;
            }

            @include mobile {
                margin: 0 0 4px;
            }
        }

        // Bottom block style start

        &-Bottom {
            display: none;

            &_isFileSelected {
                display: block;
                margin: 0 0 20px;

                @include desktop {
                    margin: 20px 0 0;
                }
            }
        }

        &-InputField {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            min-height: 32px;
            width: 100%;
            background-color: var(--surface-color);
        }

        &-FileName {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--disabled-color);
        }

        // Bottom block style end
    }

    [type='file'] {
        cursor: pointer;
        height: 100%;
        opacity: 0;
        max-width: 100%;
        z-index: 1;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;

        &::-webkit-file-upload-button {
            cursor: pointer;
        }
    }
}
