/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

@mixin arrow-icon {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline;
    width: 20px;
    height: 20px;
    position: absolute;
    margin: 3px 0 0 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 18.5C14.9706 18.5 19 14.4706 19 9.5C19 4.52944 14.9706 0.5 10 0.5C5.02944 0.5 1 4.52944 1 9.5C1 14.4706 5.02944 18.5 10 18.5Z' fill='%230559F7'/%3E%3Cpath d='M9 13L12.5 9.563L9 6' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");

    [dir="rtl"] & {
        margin: 3px 10px 0 0;
    }
}

:root {
    --menu-total-height: 0px;
}

.Menu {
    &-Item:not(:first-of-type) {
        margin-inline-start: 0;
    }

    &-MenuWrapper {
        border-block-end: 1px dashed var(--disabled-color);
        padding-block-end: 40px;

        @include desktop {
            border-inline-end: 1px dashed var(--disabled-color);
            border-block-end: 0;
            padding-block-end: 0;
        }
    }

    &-ItemCaption {
        &_type_main {
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            padding: 0;
            margin: 0;
            text-transform: capitalize;
            text-align: start;
        }

        &_item_logout {
            cursor: pointer;
        }
    }

    &-CloseButton {
        position: absolute;
        inset-inline-end: 16px;
        inset-block-start: 16px;

        svg {
            width: 28px;
            height: 28px;
        }
    }

    &-Item {
        margin-block-end: 24px;

        &:last-child {
            margin-block-end: 0;
        }

        @include desktop {
            margin-inline-start: 0;
        }
    }

    &-Sub {
        &Menu {
            > .Menu-ItemList {
                @include desktop {
                    columns: unset;
                }
            }
        }
    }

    //START SECONDARY MENU STYLE

    &-SecondaryMenuWrapper {
        display: flex;
        flex-direction: column;

        @include mobile {
            align-items: flex-start;
        }

        @include desktop {
            justify-content: center;
            align-items: center;
        }

        .Menu {
            &-SecondaryCategories {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include desktop {
                    margin-block-start: 30px;
                }

                @include mobile {
                    margin-block-start: 50px;
                }
            }

            &-ItemList_type_secondary {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .Menu-Item {
                    margin-block-start: 25px;
                    margin-block-end: 0;

                    @include mobile {
                        margin-block-start: 20px;
                    }

                    &:first-child {
                        margin-block-start: 20px;
                    }

                    &:hover {
                        .Menu-ItemCaption {
                            &_type_secondary {
                                color: var(--secondary-blue-color);
                            }

                            &::after {
                                @include arrow-icon;

                                [dir="rtl"] & {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .Menu-ItemCaption {
                        &_type_secondary {
                            color: black;

                            @include mobile {
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                }

                .Menu-ItemCaption {
                    &_type_main {
                        @include desktop {
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 52px;
                            margin: 0;
                        }
                    }

                    &_type_secondary {
                        /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                        font-family: $font-HyundaiSansHeadKR, sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        //margin-block-start: 16px;

                        @include desktop {
                            font-size: 22px;
                            line-height: 28px;
                        }

                        @include mobile {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            &-SecondaryMenuTitle {
                font-family: $font-HyundaiSansTextKR;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: var(--disabled-color);
            }
        }
    }
}
