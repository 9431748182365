/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$menuItemHeightDesktop: 84px;
$menuItemHeightMobile: 56px;
$menuPaddingDesktop: 52px;
$menuPaddingMobile: 16px;

.OrderReservation {
    &-Page {
        margin-block-start: calc(var(--breadcrumbs-height) + var(--header-height));
        min-height: calc(100vh - var(--breadcrumbs-height) - var(--header-height));
        max-width: unset;
        display: flex;
        flex-direction: column;
        padding: 0;

        @include desktop {
            flex-direction: row;
        }

        @include ultra-narrow-desktop {
            flex-direction: column;
        }

        &_isSuccessStep {
            max-width: unset;
            margin-block-start: var(--header-height);

            .OrderReservation-MainView_withInnerPadding {
                border-inline-start: none;
                padding: 0;
            }
        }

        .RequiredFields {
            &-RequiredText {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;

                @include mobile {
                    margin-inline-end: 16px;
                }
            }

            &-RequiredStar {
                color: var(--secondary-blue-color);
                margin-inline-start: 5px;
            }
        }
    }

    &-LeftMenu {
        @include desktop {
            flex-basis: $leftMenuWidth;
            max-width: 417px;
        }

        @include ultra-narrow-desktop {
            flex-basis: unset;
            display: flex;
            max-width: 100%;
        }

        .TradeInButton {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .OrderReservation-LeftMenuItem {
                width: 60%;
            }
        }
    }

    &-MainView {
        flex: 1;
        padding-inline: 16px;
        padding-block: 20px 48px;

        @include desktop {
            padding-inline: 52px;
            padding-block: 40px 108px;
            border-inline-start: 1px solid var(--stroke-color);
        }

        h1 {
            margin-block-end: 44px;
        }

        .TradeInHeader {
            &-Wrapper {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                h1 {
                    margin-block-end: 30px;
                }
            }
        }

        &_withInnerPadding {
            padding-inline: 0;
            padding-block: 0;

            @include desktop {
                padding-block-start: 40px;
                padding-block-end: 0;
            }

            h1 {
                padding-inline: 52px;
                margin-block-end: 0;
            }
        }

        .CheckoutBilling {
            margin: 0;
        }
    }

    &-LeftMenuItem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: unset;

        @include desktop {
            height: $menuItemHeightDesktop;
            border-block-end: 1px solid var(--stroke-color);
            border-inline-start: 1px solid var(--stroke-color);
            padding: 0 $menuPaddingDesktop;

            &_isCompleted {
                background-color: var(--surface-color);
                cursor: pointer;
            }
        }

        @include ultra-narrow-desktop {
            justify-content: center;
            padding: 0;
        }

        @include mobile {
            height: $menuItemHeightMobile;
            padding: 0 $menuPaddingMobile;
            justify-content: flex-start;
            gap: 7px;
        }
    }

    &-LeftMenuItemTitle {
        font-family: $font-HyundaiSansHeadKR;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: var(--separator-text-color);
        transition: all 0.1s ease-in-out;

        &_isCurrent {
            font-size: 30px;
            line-height: 32px;
            color: $black;
        }

        @include mobile {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $black;
        }
    }

    &-LeftMenuItemTick {
        width: 20px;
        height: 20px;
        background-image: $whiteTick;
        background-color: $black;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;

        @include ultra-narrow-desktop {
            margin-inline-start: 1vw;
        }

        @include mobile {
            display: none;
        }
    }

    &-ChevronIcon {
        width: 10px;
        height: 16px;
        background-image: $thinChevronIcon;
        transform: scale(0.6);
    }
}
