/* stylelint-disable declaration-no-important */

/* overrides with !important since the genesys iframe has inline styles */

.genesys-mxg {
    &-frame {
        transition: 100ms inset-block-end;
        inset-inline: unset 20px !important;

        @include desktop {
            inset-block-end: 70px !important;
        }

        @include mobile {
            inset-block-end: 140px !important;
        }
    }

    &-conversation {
        @media screen and (max-width: 600px), screen and (orientation: landscape) {
            max-height: 75% !important;
            max-width: 90% !important;
        }

        @media screen and (min-width: 600px) and (orientation: landscape) {
            max-height: 95% !important;
            inset-block-end: 12px !important;
        }
    }
}

html.filterOpened {
    .genesys-mxg-frame {
        @include mobile {
            display: none;
        }
    }
}
