/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ResetButton {
    &-Button {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;

        &:disabled {
            color: var(--body-content-color);
        }

        @include desktop {
            max-width: 126px;
        }
    }

    &-Text {
        span {
            margin-inline-start: 4px ;
        }
    }
}
