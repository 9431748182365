/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$headerBottomMarginDesktop: 16px;
$headerBottomMarginMobile: 12px;
$pictureWidth: 524px;
$pictureHeight: 340px;

@mixin bottomBorderBold {
    border-block-end: 3px solid $black;
}

.DealersOfferStep {
    &-ContentWrapper {
        padding-inline: 16px;
        padding-block-end: 32px;

        @include desktop {
            padding-inline: 52px;
            padding-block-end: 60px;
        }
    }

    &-Header {
        margin-block-end: $headerBottomMarginDesktop;

        @include mobile {
            margin-block-end: $headerBottomMarginMobile;
        }
    }

    &-SubHeading {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include desktop {
            margin-block-end: 40px;
        }
    }

    &-ModelBlock {
        @include bottomBorderBold;

        @include desktop {
            display: grid;
            grid-template-columns: 13fr 9fr;
            gap: 5%;
            padding-block-end: 60px;
        }
    }

    &-BaseInfoBlock {
        align-self: center;
    }

    &-ModelName {
        margin: 0;
        font-family: $font-HyundaiSansHeadKR;
        font-size: 30px;
        font-weight: 700;
        line-height: 32px;
        padding-block-end: 16px;

        @include bottomBorderBold;

        @include mobile {
            font-size: 26px;
        }
    }

    &-BaseInfoEntity {
        padding: 21px 0;
        border-block-end: 1px dashed var(--separator-text-color);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 24px;

        @include mobile {
            padding: 19px 0;
        }
    }

    &-BaseInfoLabel {
        font-weight: 400;
    }

    &-BaseInfoValue {
        font-weight: 700;
    }

    &-ProductAttributes {
        .ProductAttributes {
            &-ExpandableContentButton {
                padding: 24px 32px;
                gap: 40px;
                border-inline: 1px solid var(--expandable-content-stroke-color);

                &:not(.ExpandableContent-Button_isContentExpanded) {
                    border-block-end: 1px solid var(--expandable-content-stroke-color);
                }
            }

            &-AttributeBlock {
                width: 100%;
                padding: 24px 88px 24px 32px;
            }

            &-ExpandableContentContent {
                margin: 0;
                columns: unset;
            }

            &-AttributeHeader {
                @include mobile {
                    flex-direction: column;
                }

                .ProductAttributes-Group {
                    margin: 0;
                }
            }
        }

        .ExpandableContent {
            border-top: none;

            &-Content {
                background-color: var(--expandable-content-surface-color);
                border-top: none;

                @include desktop {
                    /*
                    * Made ExpandableContent work in desktop view
                    */
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                }

                /*
                * Made ExpandableContent work in desktop view
                */

                &_isContentExpanded {
                    opacity: 1;
                    max-height: 100%;
                }
            }

            &:last-of-type {
                @include mobile {
                    border: none;
                }
            }
        }

        &_isNotIos {
            .ProductAttributes {
                &-AttributeLabel {
                    @include mobile {
                        /* Using !important because of high specificity of
                        * selectors in other places where this component is used
                        */
                        /* stylelint-disable-next-line declaration-no-important */
                        padding: 0 !important;
                    }
                }
            }
        }
    }

    &-BottomBlock {
        position: sticky;
        inset-block-end: 0;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        border-block: 1px solid var(--expandable-content-stroke-color);

        @include mobile {
            height: 136px;
            flex-direction: column;
        }
    }

    &-TotalInfoBlock {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin: 4px 20px 3px;

        @include mobile {
            flex-basis: 76px;
            justify-content: flex-start;
        }
    }

    &-TotalAmountBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-inline-start: 8px;
    }

    &-TotalText {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    &-ButtonBlock {
        flex-basis: 180px;

        .Button {
            width: 100%;
        }

        @include mobile {
            flex-basis: 60px;
        }
    }

    &-TotalAmount {
        color: var(--secondary-orange-color);
        font-size: 26px;
        font-weight: 700;
        line-height: 32px;
    }

    &-TotalAmountPerMonth {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    &-TotalAmountOld {
        text-decoration: line-through;
        flex: 1;
        text-align: end;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 6px 0 26px;
        color: var(--disabled-color);

        @include mobile {
            order: 1;
            text-align: start;
            margin: 4px 0 28px 8px;
        }
    }
}
