/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

:root {
    --footer-background-color: var(--color-white);
    --footer-primary-color: var(--primary-base-color);
    --footer-stroke-color: var(--stroke-color);
}

$map-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none"><path d="M1.80248 11.0624C1.75848 11.0164 1.71548 10.9698 1.67348 10.9224L1.43948 10.6684H1.45348C0.700044 9.73482 0.217358 8.6123 0.0580414 7.42322C-0.101275 6.23415 0.0688908 5.02415 0.54999 3.92514C1.03109 2.82613 1.80466 1.88028 2.78637 1.1907C3.76808 0.501117 4.92026 0.0942683 6.11731 0.0145026C7.31435 -0.0652632 8.51033 0.185116 9.57485 0.738346C10.6394 1.29158 11.5316 2.12642 12.1543 3.15189C12.7769 4.17735 13.1061 5.35406 13.106 6.55376C13.1059 7.75346 12.7764 8.93011 12.1535 9.95542C10.6158 12.234 8.74462 14.2687 6.60248 15.9914C6.38248 15.9914 2.94148 12.2914 1.80248 11.0624Z" fill="black"/><path d="M6.43745 9.75526C8.27711 9.75526 9.76845 8.26392 9.76845 6.42426C9.76845 4.5846 8.27711 3.09326 6.43745 3.09326C4.59778 3.09326 3.10645 4.5846 3.10645 6.42426C3.10645 8.26392 4.59778 9.75526 6.43745 9.75526Z" fill="white"/></svg>');
$phone-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"><path d="M16.3673 14.4526L15.5948 15.2203C15.1039 15.7176 14.493 16.08 13.8212 16.2723C13.1494 16.4646 12.4393 16.4804 11.7596 16.3181C10.3671 15.9435 9.04361 15.3484 7.83943 14.5552C5.69724 13.1746 3.8585 11.3724 2.43522 9.25831C1.69661 8.1726 1.11195 6.98982 0.697925 5.74366C0.465291 5.0259 0.437135 4.25753 0.616601 3.52467C0.796066 2.79181 1.17607 2.1234 1.71402 1.59434L2.61792 0.690442C2.67755 0.630113 2.74856 0.58221 2.82683 0.549521C2.9051 0.516832 2.98906 0.5 3.07388 0.5C3.1587 0.5 3.24268 0.516832 3.32095 0.549521C3.39922 0.58221 3.47022 0.630113 3.52984 0.690442L6.38421 3.5448C6.44454 3.60442 6.49243 3.67542 6.52512 3.75369C6.55781 3.83196 6.57464 3.91594 6.57464 4.00076C6.57464 4.08558 6.55781 4.16956 6.52512 4.24782C6.49243 4.32609 6.44454 4.39709 6.38421 4.45672L4.70781 6.13311C4.47498 6.3631 4.33251 6.66908 4.30633 6.9953C4.28015 7.32152 4.37201 7.64629 4.56518 7.91047C5.85492 9.6745 7.42497 11.2153 9.21292 12.4717C9.47872 12.6565 9.80115 12.7418 10.1236 12.7125C10.446 12.6831 10.7478 12.5411 10.9759 12.3114L12.5962 10.6655C12.6558 10.6051 12.7268 10.5572 12.805 10.5246C12.8833 10.4919 12.9673 10.475 13.0521 10.475C13.1369 10.475 13.2209 10.4919 13.2992 10.5246C13.3774 10.5572 13.4485 10.6051 13.5081 10.6655L16.3673 13.5294C16.4293 13.5892 16.4786 13.6609 16.5123 13.7402C16.5461 13.8196 16.5634 13.9048 16.5634 13.991C16.5634 14.0772 16.5461 14.1625 16.5123 14.2418C16.4786 14.3211 16.4293 14.3928 16.3673 14.4526Z" fill="black"/></svg>');
$mail-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none"><path d="M16.9181 15.4963H1.91681C1.47474 15.4963 1.05071 15.3206 0.738118 15.008C0.425532 14.6955 0.25 14.2715 0.25 13.8295V5.38546L9.41743 10.6742L18.5849 5.3821V13.8295C18.5849 14.2715 18.4093 14.6955 18.0967 15.008C17.7842 15.3206 17.3601 15.4963 16.9181 15.4963ZM9.41743 7.79064L0.25 2.50019V2.16681C0.25 1.72474 0.425532 1.30081 0.738118 0.98822C1.05071 0.675633 1.47474 0.5 1.91681 0.5H16.9181C17.3601 0.5 17.7842 0.675633 18.0967 0.98822C18.4093 1.30081 18.5849 1.72474 18.5849 2.16681V2.50019L9.41743 7.79563V7.79064Z" fill="black"/></svg>');
$visa-icon: url("data:image/svg+xml,%3Csvg width='35' height='24' viewBox='0 0 35 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='34' height='23' rx='2.5' fill='white' stroke='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.6253 16.2582H8.50494L6.91495 10.1924C6.83949 9.91334 6.67925 9.66667 6.44354 9.5504C5.85531 9.25823 5.20712 9.0257 4.5 8.90843V8.6749H7.91567C8.38708 8.6749 8.74064 9.0257 8.79957 9.43313L9.62454 13.8086L11.7438 8.6749H13.8052L10.6253 16.2582ZM14.9838 16.2582H12.9813L14.6302 8.6749H16.6327L14.9838 16.2582ZM19.2234 10.7757C19.2823 10.3673 19.6359 10.1337 20.0483 10.1337C20.6965 10.0751 21.4026 10.1924 21.9919 10.4835L22.3454 8.85081C21.7562 8.61727 21.108 8.5 20.5198 8.5C18.5762 8.5 17.162 9.55041 17.162 11.0082C17.162 12.1173 18.1637 12.6996 18.8708 13.0504C19.6359 13.4002 19.9305 13.6338 19.8716 13.9835C19.8716 14.5082 19.2823 14.7418 18.6941 14.7418C17.9869 14.7418 17.2798 14.5669 16.6327 14.2747L16.2791 15.9085C16.9862 16.1996 17.7512 16.3169 18.4584 16.3169C20.6376 16.3745 21.9919 15.3251 21.9919 13.75C21.9919 11.7665 19.2234 11.6502 19.2234 10.7757ZM29 16.2582L27.41 8.6749H25.7022C25.3486 8.6749 24.9951 8.90843 24.8772 9.25823L21.9329 16.2582H23.9943L24.4058 15.1502H26.9386L27.1743 16.2582H29ZM25.9968 10.7171L26.585 13.5751H24.9361L25.9968 10.7171Z' fill='%23172B85'/%3E%3C/svg%3E ");
$mc-icon: url("data:image/svg+xml,%3Csvg width='35' height='24' viewBox='0 0 35 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='34' height='23' rx='2.5' fill='white' stroke='%23D9D9D9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z' fill='%23ED0006'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z' fill='%23F9A000'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.75 6.64917C19.2172 7.9153 20.1476 9.79929 20.1476 11.9031C20.1476 14.0069 19.2172 15.8909 17.75 17.157C16.2829 15.8909 15.3525 14.0069 15.3525 11.9031C15.3525 9.79929 16.2829 7.9153 17.75 6.64917Z' fill='%23FF5E00'/%3E%3C/svg%3E%0A");


$face-icon: url('data:image/svg+xml;utf8,');


.Footer {
    padding: 0;
    border-block-start: 1px solid var(--footer-stroke-color);
    margin: 0;

    .ContentWrapper {
        @include desktop {
            max-width: unset;
        }
    }

    .CmsBlock-Wrapper {
        .Footer-Columns {
            @include desktop {
                padding: 48px 52px;
            }

            @include mobile {
                padding: 24px 16px;
            }
        }
    }

    &-Columns {
        text-align: start;
        padding: 0;

        @include desktop {
            height: $footerHeightDesktop;
            margin: 0 !important;
        }

        @include mobile {
            align-items: stretch;
        }
    }

    &-Column {
        width: 100%;
        flex: 1;
        border-block-start: 1px dashed var(--footer-stroke-color);
        padding: 36px 0;

        &:first-child {
            padding: 0;
            border-block: none;

            @include mobile {
                display: flex;
                flex-direction: column;
            }

            @include desktop {
                border-inline-start: none;
                width: 19%;
            }
        }

        &:nth-child(2) {
            border-block-start: none;

            @include desktop {
                width: 25%;
            }
        }

        &:nth-child(3) {
            @include desktop {
                flex: 2;
                width: 36%;
            }
        }

        &:last-child {
            @include desktop {
                width: 20%;
                padding: 0 0 0 36px;
            }

            @include mobile {
                padding-block-end: 0;
            }
        }

        &:not(:last-of-type) {
            margin: 0;

            @include desktop {
                padding-inline-end: 36px;
            }
        }

        &-ColumnContent {
            line-height: 24px;
            font-size: 16px;
        }

        @include desktop {
            width: 100%;
            border-block: none;
            flex-direction: row;
            padding: 0 36px;
            border-inline-start: 1px dashed var(--footer-stroke-color);
        }
    }

    &-MapIcon,
    &-PhoneIcon,
    &-EmailIcon {
        padding-inline-start: 10px;
        line-height: 24px;

        @include mobile {
            max-width: 90%;
            margin-block-end: 25px;
            margin-inline: 25px !important;
        }

        &::before {
            height: 16px;
            width: 16px;
            display: inline-block;
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            inset-block-start: 0;
            inset-inline-start: -21px;
            position: absolute;
        }
    }

    &-MapIcon {
        @media (max-width: 531px) {
            &::before {
                inset-block-start: 10px;
            }
        }

        &::before {
            background-image: $map-icon;
        }
    }

    &-PhoneIcon {
        &::before {
            inset-block-start: 5px;
            background-image: $phone-icon;
        }
    }

    &-EmailIcon {
        &::before {
            inset-block-start: 0;
            background-image: $mail-icon;
        }
    }

    &-ColumnItem {
        font-size: 16px;
        font-weight: 400;

        &:hover {
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    &-ColumnTitle {
        margin-block-end: 12px;
        font-weight: 700;
        font-size: 16px;
    }

    &-Logo {
        margin-block-end: 36px;
        
        @include mobile {
            width: 134px;
        }
    }

    &-SecondaryLogo {
        @include mobile {
            width: 134px;
        }
    }

    &-ColumnContent_direction_horizontal {
        justify-content: flex-start;
    }

    .NewsletterSubscription {
        @include desktop {
            min-width: unset;
        }

        .FieldForm {
            &-Fieldset {
                .Field input {
                    @include desktop {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        height: 48px;
                        padding: 12px 20px;
                        border: 1px solid var(--footer-stroke-color);
                    }
                }

                @include tablet {
                    width: 100%;
                }
            }

            &-Body {
                @include desktop {
                    display: flex;
                }

                @include tablet {
                    display: block;
                    width: 100%;
                }
            }

            &-Fields {
                width: 100%;
            }
        }

        .Form {
            width: 100%;
        }

        .Button {
            background-color: var(--footer-primary-color);
            color: var(--footer-background-color);
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            margin: 12px 0 0;
            border: none;

            @include desktop {
                height: 48px;
                width: 120px;
                padding: 12px 20px;
                margin-block-start: 0;
                margin-inline-start: 4px;
            }

            @include tablet {
                margin: 12px 0 0;
                width: 100%;
                margin-inline-start: 0;
            }
        }

        @include mobile {
            flex-direction: column;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
        }

        &-TermsAndConditions-CheckboxFormMobile {
            margin-block-start: 10px !important;

            @include desktop {
                display: none;
            }

            @include tablet {
                display: block;
            }
        }

        &-TermsAndConditions-Checkbox {
            @include tablet { 
                display: none;
            }

            @include mobile {
                display: none;
            }
        }
    }

    &-Copyright {
        color: var(--disabled-color);
        font-size: 14px;
        line-height: 20px;
        position: relative;
        width: 100vw;
        display: flex;
        align-items: center;
        background-color: #F4F4F4;
        
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: black;
            opacity: 0.7;

            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: $font-HyundaiSansTextKR;
                color: black;
            }
        }

        @include desktop {
            justify-content: space-between;
            padding: 0 52px;
            height: $footerCopyrightDesktop;
        }

        @include mobile {
            flex-direction: column-reverse;
            padding: 14px 40px;
            height: 96px;
            justify-content: space-between;
        }
    }

    &-SocialLogo {
        width: 20px;
        height: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    &-SocialIcons {
        margin-block-end: 20px;

        @include desktop {
            width: 100%;
            gap: 34px;
            justify-content: flex-end;
        }

        @include mobile {
            justify-content: start;
            gap: 36px;
            margin-block-start: 12px;
            margin-block-end: 24px;
        }
    }

    &-PaymentLogos {
        display: flex;

        @include mobile {
            justify-content: flex-start;
        }

        @include desktop {
            justify-content: flex-end;
        }

        .Footer {
            &-MasterCardLogo {
                height: 24px;
                width: 35px;
                background-image: $mc-icon;
            }

            &-VisaLogo {
                height: 24px;
                width: 35px;
                background-image: $visa-icon;
                margin-inline-start: 16px;
            }
        }
    }

    &-PrivacyPolicy {
        display: flex;
        flex-direction: column;

        &:hover {
            text-decoration: unset;
        }

        @include desktop {
            align-items: end;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        span {
            @include mobile {
                color: var(--primary-base-color);
                margin-block-end: 8px;
            }
        }
    }

    &-Content {
        background-color: var(--footer-background-color);
        height: unset;
    }

    @include desktop {
        padding: 0;
        height: 312px;
        overflow: unset;
    }
}
