/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CookiePopup {
    &-Content {
        p, a {
            font-size: 12px;
        }
    }

    a, &-CTA {
        transition: color 150ms ease-in-out;
        color: var(--secondary-blue-color);

        &:hover {
            color: var(--secondary-orange-color);
        }
    }
}
