/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.DeliveryStep {
    &-Selector {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;
        column-gap: 24px;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &-Radio {
        &.Field {
            margin-block-start: 0;
        }

        .Field {
            &-RadioLabel {
                width: 100%;
                padding: 26px 20px;
                border: 1px solid var(--stroke-color);
            }
        }

        &_isChecked {
            .Field {
                &-RadioLabel {
                    border-color: var(--secondary-blue-color);
                    // added the outline to get rid of content jumps
                    outline: 1px solid var(--secondary-blue-color);
                }
            }
        }
    }

    &-ButtonWrapper {
        margin-block-start: 40px;

        @include desktop {
            margin-block-start: 44px;
        }
    }

    &-Button {
        @include desktop {
            width: 300px;
        }
    }
}
