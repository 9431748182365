/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Breadcrumb {
    .ChevronIcon {
        margin-inline-start: 0;
        fill: var(--disabled-color);
        filter: unset;
    }

    &-Link {
        color: var( --breadcrumbs-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        pointer-events: all;
        cursor: pointer;

        &:hover {
            color: var(--breadcrumbs-hover-color);
        }

        span {
            margin-inline-start: 8px;
            color: var(--disabled-color);

            @include desktop {
                margin-inline: 8px 0;
            }
        }
    }

    &:last-child {
        margin-block-end: 18px;

        .ChevronIcon {
            display: inline-block;
        }

        :link {
            pointer-events: all;
        }
    }
}
