/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.FieldSelect {
    &-Clickable, &-Select {
        font-size: 16px;
    }

    &-Select {
        color: var(--disabled-color);

        &_isSelected {
            color: var(--body-content-color);
        }

        &:disabled + svg {
            fill: var(--disabled-color);
        }
    }

    &-Options {
        &::-webkit-scrollbar {
            width: 4px;

            @include desktop {
                width: 8px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: var(--negative-color);
        }

        &::-webkit-scrollbar-track {
            background: var(--surface-color);
            border-left: 1px solid #D9D9D9;
        }

        &_expandingIsOver {
            &::-webkit-scrollbar {
                display: unset;
            }

            &::-webkit-scrollbar-thumb {
                display: unset;
            }
        }

        &_isExpanded {
            @include desktop {
                max-height: 200px;
            }
        }
    }

    &-Option {
        padding-inline-start: 20px;
        padding-block: 8px;
        line-height: 24px;
        max-height: 40px;

        &:hover, &:focus {
            @include desktop {
                background-color: var(--secondary-blue-color);
                color: var(--background-color);
            }
        }

        &_isHovered {
            @include desktop {
                background-color: var(--secondary-blue-color);
                color: var(--background-color);
            }
        }

        &_isPlaceholder {
            opacity: 1;
            color: var(--disabled-color);
        }
    }

    .ChevronIcon {
        fill: var(--body-content-color);
    }
}
